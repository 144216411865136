// import AuthUser from 'Auth/AuthUser'
import axios from 'axios'
import Header from 'components/Headers/Header'
import { Url } from 'constants/urls'
// import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'

import React, { useState } from 'react'
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Form, FormGroup, Input, Button, FormFeedback, Container } from 'reactstrap'
import AuthUser from 'services/auth/AuthUser'
import Swal from 'sweetalert2'

function ChangePassword() {
  const {getToken}=AuthUser()
  const [errors,setErrors]=useState('')
  const [multiErrors,setmultErrors]=useState({
    currentPasswordErr:'',
    newPWErr:'',
    newPwConfErr:'',
  })
  const [confidentials,setConfidentials]=useState({
    currentPassword:'',
    newPassword:'',
    newPassword_confirmation:'',
  })
  function handleChange(e){
      setConfidentials(prev=>{
          return{
              ...prev,
              [e.target.name]:e.target.value,
          }
      })
  }
  // console.log(confidentials)
  //change password function
  function changePassword(e){
    e.preventDefault()
    axios.post(`${Url}auth/change-password`,confidentials,{
      headers:{
        'Content':'Application/json',
        "Accept":'application/json',
        "Authorization":`Bearer ${getToken()}`,
      }
    }).then((response)=>{
      setConfidentials({
        currentPassword:'',
        newPassword:'',
        newPassword_confirmation:'',
      })
      Swal.fire({
        icon:response.data.status,
        title:'Password change',
        text:response.data.message,
        showConfirmButton:false,
        timer:response.data.status==="warning"?5000:2400
      })
      setmultErrors({
        currentPasswordErr:'',
        newPWErr:'',
        newPwConfErr:'',
      })
      setErrors('') //id there is errors previouslly

    }).catch((e)=>{
      setErrors('errors')

      // console.log(e)
      if(e.response.data.errors.currentPassword)
      {
        setmultErrors({
          currentPasswordErr:e.response.data.errors.currentPassword[0],
          newPwConfErr:e.response.data.errors.newPassword[0]
        })
      }else if(e.response.data.errors.newPassword.length>1){
        setmultErrors({
          currentPasswordErr:'',
          newPWErr:e.response.data.errors.newPassword[0],
          newPwConfErr:e.response.data.errors.newPassword[1]
        })
      }
      else{   
        setmultErrors({
          currentPasswordErr:'',
          newPwConfErr:e.response.data.errors.newPassword[0]
        })
      }
      setConfidentials({
        currentPassword:'',
        newPassword:'',
        newPassword_confirmation:''
      })
      // Swal.fire({
      //   status:'danger',
      //   text:response.data.message,
      // })
    })
  }
//   console.log(errors)
// console.log(confidentials)
  return (
    <div>
        <Header />
      {/* Page content */}
        <Container className="mt--7" fluid>
        <div className='mx-5'>
                {/* <Breadcrumbs/> */}
                <Row>
                <Col md="12">
                    <Card className="card-user">
                    <CardHeader>
                        <CardTitle tag="h5">Change Password</CardTitle>
                    </CardHeader>
                    <CardBody className='mx-4'>
                        <Form onSubmit={changePassword}>
                        <Row>
                            <Col md='11'>
                            <FormGroup>
                                <label htmlFor="exampleInputEmail1">
                                Current Password
                                </label>
                                <Input
                                    name='currentPassword' 
                                    placeholder="************" 
                                    type="password" 
                                    value={confidentials.currentPassword}
                                    onChange={handleChange}
                                    invalid={multiErrors.currentPasswordErr?true:false}
                                    required/>
                                    {multiErrors.currentPasswordErr&&
                                    <FormFeedback>
                                    {multiErrors.currentPasswordErr}
                                    </FormFeedback>}
                            </FormGroup>
                            
                            </Col>
                        </Row>
                        <Row>
                            <Col  md='11'>
                            <FormGroup>
                                <label htmlFor="exampleInputEmail1">
                                    New password
                                </label>
                                <Input
                                    name='newPassword' 
                                    placeholder="************" 
                                    type="password" 
                                    value={confidentials.newPassword}
                                    onChange={handleChange}
                                    invalid={errors?true:false}
                                    required
                                />
                                {
                                multiErrors.newPWErr &&
                                <FormFeedback>
                                    {multiErrors.newPWErr}
                                </FormFeedback>
                                }
                            </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col  md='11'>
                            <FormGroup>
                                <label htmlFor="exampleInputEmail1">
                                    New password confirmation
                                </label>
                                <Input
                                    name='newPassword_confirmation' 
                                    placeholder="************" 
                                    type="password" 
                                    value={confidentials.newPassword_confirmation}
                                    onChange={handleChange}
                                    invalid={errors?true:false}
                                    required
                                />
                                {errors &&<>
                                    {/* { confidentials.newPassword != confidentials.newPassword_confirmation ?
                                    <FormFeedback>
                                        {"password confirmation doesn't match"}
                                    </FormFeedback>:
                                    <FormFeedback>
                                    </FormFeedback>} */}
                                    <FormFeedback>
                                    {multiErrors.newPwConfErr}
                                    </FormFeedback>
                                </>}
                            </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <div className="update ml-3 float-left">
                            {/* <Link to={profileData.role?`/agent-profile/${props.id}`:(!props.isAgent && `/client-profile/${props.id}`)  }> */}
                            
                                <Button
                                className="rounded "
                                color="primary"
                                type="submit" 
                                >
                                apply changes
                                </Button>
                            
                            </div>
                        </Row>
                        </Form>
                    </CardBody>
                    </Card>
                </Col>
                </Row>
            </div>

        </Container>
    </div>
  )
}

export default ChangePassword