import axios from 'axios'
// import { BaseUrl } from 'constants/urls'
import { Url } from 'constants/urls'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'


export default function AuthUser() {
    const navigate = useNavigate()
    const getToken=()=>{
        const tokenString= sessionStorage.getItem('token')
        const userToken=JSON.parse(tokenString)

        return userToken
    }
    const getUser=()=>{
        const userString= sessionStorage.getItem('user')
        const user_detail=JSON.parse(userString)

        return user_detail
    }

    const [token,setToken]= useState(getToken());
    const [user,setUser]=useState(getUser())

    const saveToken=(user,token)=>{
        sessionStorage.setItem('token',JSON.stringify(token))
        sessionStorage.setItem('user',JSON.stringify(user))
        setToken(token)
        setUser(user)
        navigate('/admin')
    }
    
    const logout=()=>{
        axios.post(`${Url}auth/logout`,[],{
            headers:{
                "Content-Type":'application/json',
                "Accept":'application/json',
                "Authorization":`Bearer ${getToken()}`
            }
        })
        sessionStorage.clear()
        navigate('/')
        // navigate('/connection')
    }
    const authUrl= axios.create({
        baseURL:`${Url}auth`, //"http://127.0.0.1:8000/api/auth"
        headers:{
            "content-type":"application/json",
            // "bearer": token
        }
    })
  return (
    {
        authUrl,
        token,
        getToken,
        user,
        setToken:saveToken,
        logout,
    }
  )
}
