/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  FormFeedback,
  Label,
} from "reactstrap";
import AuthUser from "services/auth/AuthUser";

const Login = () => {
    const {authUrl,setToken} = AuthUser()
    const [email,setEmail]=useState('')
    const [password,setpassword]=useState('')
    const [errors,setErrors]=useState(false)
    // console.log(authUrl)
    function signIn(e){
      e.preventDefault()
      authUrl.post('/login',{
          email:email,
          password:password,
      }).then((res)=>{
          setToken(
              res.data.user,
              res.data.token,
            )
      }).catch(
          (e)=>{
              console.log(`error ${e}`)
              setErrors(true)
              setpassword('');
          }
      
          )
      
  }
  // console.log(password,email)
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent ">
            <div className="text-muted text-center mt-2 mb-2">
              <h2>Sign in</h2>
            </div>
            <div className="btn-wrapper text-center">
              {/* <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/github.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Github</span>
              </Button> */}
              {/* <Button
                className="btn-neutral btn-icon"
                color="default"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/google.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Google</span>
              </Button> */}
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            {/* <div className="text-center text-muted mb-4">
              <small>Or sign in with credentials</small>
            </div> */}
            <form onSubmit={signIn}>
              <FormGroup className="mb-3">
                  <Label>Email</Label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    name="email"
                    value={email}
                    autoComplete="new-email"
                    onChange={e=>{setEmail(e.target.value)}}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label>Password</Label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    name='password'
                    autoComplete="new-password"
                    value={password}
                    onChange={e=>{setpassword(e.target.value)}}
                    invalid={errors?true:false}
                    required
                  />
                  {errors &&<>
            
                    <FormFeedback>
                      password or email are incorrect
                    </FormFeedback>
                   </>}
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div>
              <div className="text-center">
                <Button type='submit' className="my-4" color="primary" >
                  Sign in
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link
              className="text-light"
              to="/auth/forgot-password"
              
            >
              <small>Forgot password?</small>
            </Link>
          </Col>
          {/* <Col className="text-right" xs="6">
            <Link to="/auth/register"
              className="text-light"
              
              // onClick={(e) => e.preventDefault()}
            >
              <small>Create new account</small>
            </Link>
          </Col> */}
        </Row>
      </Col>
    </>
  );
};

export default Login;
