/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import 'assets/css/argon-dashboard-react.css'

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
// import Tables from "views/examples/Tables";
import AddRoom from "views/rooms/AddRoom";
import UpdateRoom from "views/rooms/UpdateRoom";
import ChangePassword from "views/profile/ChangePassword";
import IdleTimerContainer from "components/idleTimer/IdleTimerContainer";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <IdleTimerContainer/>
    <Routes>
      <Route path="/admin/*" element={<AdminLayout />}>
        <Route path="rooms-type/*" element={<AdminLayout />} />
      </Route>
      {/* <Route path="/change-password" element={<ChangePassword />} /> */}
      <Route path="/add-room" element={<AddRoom />} />
      <Route path="/room/:slug" element={<UpdateRoom />} />
      <Route path="/auth/*" element={<AuthLayout />} />
      <Route path="*" element={<Navigate to="/auth/" replace />} />
    </Routes>
  </BrowserRouter>
);
