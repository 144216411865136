
import { useState } from "react";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Label,
  } from "reactstrap";
import AuthUser from "services/auth/AuthUser";
import Swal from "sweetalert2";
  
  const ForgotPassword = () => {
    const [email,setEmail]=useState('')
    const [isSent,setIsSent]=useState(false)
    const {authUrl}=AuthUser()
    const sendEmail= (e)=>{
        e.preventDefault()
        authUrl.post(`/forgot-password`,{
            email:email
        },{
            Headers:{
                "Accept":"application/json"
            }
        })
        .then((res)=>{
            const message=res.data.status
            setIsSent(true)
            // setEmail('')
            Swal.fire({
                icon:'success',
                title:message,
                target:'body'
            })
        }).catch(
            (res)=>{
                console.log(res)
                setEmail('')
            })
        }
    return (
      <>
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent ">
              <div className="text-muted text-center mt-2 mb-2">
                <h2> Forgot your password ? </h2>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <form onSubmit={sendEmail}>
                {!isSent?<FormGroup>
                    <Label>Email:</Label>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      name="email"
                      autoComplete="new-email"
                      value={email}
                      required
                      onChange={(e)=>setEmail(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>:
                    <div>
                        	<h2 className="text-center">
                                we have sent the reset email, check your email : {email}
                            </h2>
                    </div>}
            
                <div className="text-center">
                  <Button className="mt-4" color="primary" type="submit">
                    {isSent?"Resend":"Send"} reset email
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
          <Row className="mt-3">
          <Col className="text-left" xs="6">
            <Link to="/auth/login"
              className="text-light"
              
              // onClick={(e) => e.preventDefault()}
            >
              <small>Sign in</small>
            </Link>
          </Col>
        </Row>
        </Col>
      </>
    );
  };
  
  export default ForgotPassword;
  