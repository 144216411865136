import axios from 'axios';
// import AuthUser from '../auth/AuthUser'; // Adjust the import according to your project structure
import { Url } from 'constants/urls';
import { getToken } from 'services/auth/Token';


// const {getToken} = AuthUser()
const fetchRoomTypes = async () => {
    try {
        const response = await axios.get(`${Url}roomtype`, {
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        });
        return response.data.room_types;
    } catch (e) {
        console.error('Error fetching room types:', e);
        throw e;
    }
};

export { fetchRoomTypes };