import axios from 'axios';
import { Url } from 'constants/urls';
import React, { useState } from 'react'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import AuthUser from 'services/auth/AuthUser';

function RoomTypeForm(props) {
    const { getToken} = AuthUser()
    const [roomtypeData,setRoomtypeData]=useState({
        name:'',
        name_fr:'',
        name_nl:'',
    });
    const handleChange = (event) => {
        const { name, value } = event.target;
        setRoomtypeData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const createRoomType= async (e)=>{
        e.preventDefault();
        
        await axios.post(`${Url}roomtype`,roomtypeData,{
            headers:{
                'Accept':'application/json',
                "Content-Type": "multipart/form-data",            
                "Authorization":`Bearer ${getToken()}`
            }
        }).then((res)=>{
            console.log(res.data.message)
            props.onLoad()
            props.close()
        }).catch(e=>console.log(e))
    }
    if(!props.isOpen) return null
    return (
    <div>
        <form onSubmit={createRoomType}>
            
            <div className='float-right text-xl '>
                <button className='bg-transparent border-0' onClick={()=>props.close()} title='close window'> 
                    <i className='ni ni-fat-remove text-danger'/>
                </button>
            </div>
            <Row>
                <Col>
                    <FormGroup>
                        <Label for="exampleEmail">
                            type name
                        </Label>
                        <Input 
                            type='text'
                            name='name'
                            placeholder='name '
                            value={roomtypeData.name}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for="exampleEmail">
                            type name (fr)
                        </Label>
                        <Input 
                            type='text'
                            name='name_fr'
                            placeholder='name in french'
                            value={roomtypeData.name_fr}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for="exampleEmail">
                            type name (dutch)
                        </Label>
                        <Input 
                            type='text'
                            name='name_nl'
                            placeholder='name in dutch'
                            value={roomtypeData.name_nl}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Button >
                Add
            </Button>
        </form>
    </div>
  )
}

export default RoomTypeForm