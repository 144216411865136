import Header from 'components/Headers/Header'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Badge, Card, CardFooter, CardHeader, Container, DropdownItem, DropdownMenu, DropdownToggle, Media, Progress, Table, UncontrolledDropdown, UncontrolledTooltip } from 'reactstrap'
import { fetchRooms } from 'services/rooms/RoomsService';
import { Url } from 'constants/urls';
import axios from 'axios';
import Swal from 'sweetalert2';
import AuthUser from 'services/auth/AuthUser';
function Rooms() {
    const [rooms, setRooms]=useState([])
    // console.log(Url)
    const {getToken}= AuthUser()
    const fetchData = async () => {
        try {
            const roomsData = await fetchRooms();
            setRooms(roomsData);
            // console.log(roomsData)
        } catch (error) {
            console.error('Error fetching rooms data:', error);
        }
    };
    // console.log(rooms)
    function deleteRoom(id){
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCloseButton:true,
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`${Url}rooms/room/${id}`,{
            headers:{
              'Authorization':`Bearer ${getToken()}`
            }
          })
          .then((res)=>{
            Swal.fire({
              icon:'success',
              // title:'mission has been updated',
              text:res.data.message,
              showConfirmButton:false,
              timer: 2000,
          })
          fetchData()
          // console.log(res.data.message)
          })
          .catch(e=>console.log(e));

        }})
    }
    useEffect(()=>{
        fetchData()
    },[])
  return (
    <>
      <Header />
      {/* Page content */}
        <Container className="mt--7" fluid>
        <Card className="shadow">
              <CardHeader className="border-0 flex ">
                <h3 className="mb-4">Rooms</h3>
                <Link to='/add-room'>
                    <button className='btn btn-primary' >
                        + add rooms
                    </button>
                </Link>
              </CardHeader>
              <Table  className=" table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" >Image</th>
                    <th scope="col" >Name</th>
                    <th scope="col" >Type</th>
                    <th scope="col">Description</th>
                    <th scope="col" >Size (m²)</th>
                    <th scope="col" >Capacity (persons)</th>
                    <th scope="col" >Price (€)</th>
                    
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {rooms.map((room)=>
                    <tr key={room.id}>
                        <td>
                            <img src={`${process.env.REACT_APP_URL}/${room.main_image}`} alt={`${room.slug}`} style={{width:'150px',height:'150px'}}/>
                        </td>
                        <td>
                            {room.name}
                        </td>
                        <td>
                            {room.room_type.name}
                        </td>
                        <td maxWidth="md" className='desc-col'>
                            <p >
                             {room.description}
                            </p>
                        </td>
                        <td className='text-center'>
                            {room.size}
                        </td>
                        <td className='text-center'>
                            {room.capacity}
                        </td>
                        <td>
                            {room.price}
                        </td>
                        <td>
                          <button className='bg-transparent border-0 text-danger' onClick={()=>deleteRoom(room.id)}>
                            delete
                          </button>
                          <Link to={`/room/${room.slug}`}>
                            view
                          </Link>
                        </td>
                        
                    </tr>)}
                  <tr>
                    {/* <th scope="row">
                      <Media className="align-items-center">
                        <a
                          className="avatar rounded-circle mr-3"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <img
                            alt="..."
                            src={require("../assets/img/theme/angular.jpg")}
                          />
                        </a>
                        <Media>
                          <span className="mb-0 text-sm">
                            Angular Now UI Kit PRO
                          </span>
                        </Media>
                      </Media>
                    </th> */}
                    
                  </tr>
                  <tr>
                   
                  </tr>
                  <tr>
                   
                  </tr>
                  <tr>
                   
                  </tr>
                  <tr>

                  </tr>
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  
                </nav>
              </CardFooter>
            </Card>
        
        </Container>
        
    </>
  )
}

export default Rooms