import axios from "axios";
// import AuthUser from "services/auth/AuthUser";
import{ Url}  from "constants/urls";

// 

export async function fetchRooms() {
    try {
        const response = await axios.get(`${Url}rooms`)
        return response.data.rooms
    }
    catch(e){
        console.error('Error fetching rooms data:', e);
        throw e;
    }
}
// export async function fetchRoomType() {
//         const {getToken} = AuthUser()
//         try {
//         const response = await axios.get(`${Url}roomtype`,{
//             headers:{
//                 'Authorization':`Bearer ${getToken()}`
//             }
//         })
//         return response.data.room_types
//     }
//     catch(e){
//         console.error('Error fetching rooms types:', e);
//         throw e;
//     }
// }