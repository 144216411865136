import axios from 'axios'
import RoomTypeForm from 'components/Forms/RoomTypeForm'
import RoomTypeUpdateform from 'components/Forms/RoomTypeUpdateform'
import Header from 'components/Headers/Header'
import { Url } from 'constants/urls'
import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader, Container, Table } from 'reactstrap'
import AuthUser from 'services/auth/AuthUser'
import { fetchRoomTypes } from 'services/rooms/RoomTypes'
import Swal from 'sweetalert2'

function RoomTypes() {
    const [roomTypes, setRoomTypes]=useState([])
    const {getToken} = AuthUser()
    const [ openCreateForm , setOpenCreateForm]=useState(false)
    const [ openUpdateForm , setOpenUpdateForm]=useState(false)
    const [selectedRoomType, setSelectedRoomType]=useState('')
    async function fetchData() {
        // try {
        // await axios.get(`${Url}roomtype`,{
        //         headers:{
        //             'Authorization':`Bearer ${getToken()}`
        //         }
        //     }).then(res => {
        //         const types = res.data.room_types
        //         setRoomTypes(types)
        //     }).catch(e=>console.log(e))
        // // return response.data.room_types
        // }
        // catch(e){
        // console.error('Error fetching rooms types:', e);
        // throw e;
        // }
        try {
            const types = await fetchRoomTypes();
            setRoomTypes(types);
        } catch (e) {
            console.error('Failed to fetch room types:', e);
        }
    }
    function onClose(){
        setOpenCreateForm(false)
    }
    function onCloseUpdate(){
        setOpenUpdateForm(false)
    }
    async function deleteType(id){
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCloseButton:true,
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                 axios.delete(`${Url}roomtype/${id}`,{
                    headers:{
                        'Authorization':`Bearer ${getToken()}`
                    }
                }).then((res)=>{
                    Swal.fire({
                        icon:'success',
                        // title:'mission has been updated',
                        text:res.data.message,
                        showConfirmButton:false,
                        timer: 2000,
                    });
                    fetchData()
                    console.log(res.data.message)
                }).catch(e=>console.log(e))
            }
            })}
    useEffect(()=>{
        fetchData()
    },[])
    // console.log(selectedRoomType)
  return (
    <>
        <Header />
      {/* Page content */}
        <Container className="mt--7" fluid>
            <Card className="shadow">
              <CardHeader className="border-0 flex ">
                <h3 className="mb-4">Room Types</h3>
                {!openCreateForm ?<Button className='p-2 btn-success' onClick={()=>setOpenCreateForm(true)}>
                   + add Type
                </Button>: <></>}
                {
                    openCreateForm &&
                    <RoomTypeForm isOpen={openCreateForm} close={onClose} onLoad={fetchData}/>
                }
                {
                    openUpdateForm &&
                    <RoomTypeUpdateform isOpen={openUpdateForm} roomTypeId={selectedRoomType} close={onCloseUpdate} onLoad={fetchData}/>
                }
                </CardHeader>
                <Table  className=" table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                        <th scope="col" >id</th>
                        <th scope="col" >Name</th>
                        <th scope="col">Name (français)</th>
                        <th scope="col" >Name (Dutch)</th>
                        <th scope="col" />
                    </tr>
                    </thead>
                    <tbody>
                        {roomTypes.map((type,index)=>
                            <tr key={type.id}>
                                <td>
                                    {index+1}
                                </td>
                                <td>
                                    {type.name}
                                </td>
                                <td>
                                    {type.name_fr}
                                </td>
                                <td>
                                    {type.name_nl}
                                </td>
                                <td>
                                    <button className='bg-transparent border-0' onClick={()=>{setSelectedRoomType(type.id);setOpenUpdateForm(true)}}>
                                        <i className={'ni ni-ruler-pencil text-info'} />
                                    </button>
                                    <button className='bg-transparent border-0' onClick={()=>deleteType(type.id)}>
                                        <i className={'ni ni-fat-delete text-danger'} />
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card>
        </Container>
    </>
  )
}

export default RoomTypes