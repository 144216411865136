import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Label,
    FormFeedback,
  } from "reactstrap";
import AuthUser from "services/auth/AuthUser";
import Swal from "sweetalert2";
import FormData from 'form-data';
  
  const ResetPassword = () => {
    const {id}=useParams();
    const navigate=useNavigate()
    const {authUrl}=AuthUser()
    const token=id;
    const [email,setEmail]=useState('');
    const [password,setPassword]=useState('')
    const [password_confirmation,setPasswordConfirm]=useState('')
    const [errors,setErrors]=useState('')
    const [errorsCall,setErrorsCall]=useState({
        pwErr:'',
        pwConfErr:'',
    })
    const ResetPassword= (e)=>{
        e.preventDefault()
        const formData= new FormData();
        formData.append('token',token)
        formData.append('email',email)
        formData.append('password',password)
        formData.append('password_confirmation',password_confirmation)
        authUrl.post('/reset-password',formData,{
          headers:{
            'Accept':'application/json'
          }
        }).then((res)=>{
          const mesg=res.data.message
          setEmail('')
          setPassword('')
          setPasswordConfirm('')
        //   setSuccess(true)
          setErrors('')
          // console.log(mesg)
          //signIn(e)
          Swal.fire({
            icon:'success',
            title:mesg,
            confirmButtonText: 'Sign in!'
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/auth/login')
            }})
          
        }).catch((e)=>{
            // console.log(e)
          setErrors('password error')
          setPassword('')
          setPasswordConfirm('')
          if(e.response.data.errors.password)
          {   
            if(e.response.data.errors.password.length>1)
              {
                  setErrorsCall({
                      pwErr:e.response.data.errors.password[1],
                      pwConfErr:e.response.data.errors.password[0]
                  })
              }else{
                  setErrorsCall({
                      pwErr:'',
                      pwConfErr:e.response.data.errors.password[0],
                  })
              }
          }
          if(e.response.data.message){
            Swal.fire({
                icon:'warning',
                title:"token is invalid",
                // confirmButtonText: 'Sign in!'
                timer:3000
            })
          }
        })
      }
    return (
      <>
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent ">
              <div className="text-muted text-center mt-2 mb-2">
                <h2> Reset password </h2>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <form onSubmit={ResetPassword}>
              <FormGroup>
                  <label>Email</label>
                  <Input type='email' name='email' className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="email" placeholder="xyz@example.com" value={email} onChange={(e)=>{setEmail(e.target.value)}} required/>
                </FormGroup>
                <FormGroup>
                  <label>Password</label>
                  <Input type='password' name='password' className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="password" placeholder="password"  value={password} onChange={(e)=>{setPassword(e.target.value)}} required invalid={errors?true:false}/>
                  {errors &&<>
                        
                        <FormFeedback>
                            { errorsCall.pwErr}
                        </FormFeedback>
                    </>}
                </FormGroup>
                <FormGroup>
                  <label>Password Confirmation</label>
                  <Input type='password' name='password_confirm' className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="password_confirm" placeholder="password" value={password_confirmation} onChange={(e)=>{setPasswordConfirm(e.target.value)}} required invalid={errors?true:false}/>
                  {errors &&<>
                        
                        <FormFeedback>
                            {errorsCall.pwConfErr}
                        </FormFeedback>
                    </>}
                </FormGroup>
                  {/* <Label>Password</Label>
                    <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    name='password'
                    autoComplete="new-password"
                    value={password}
                    onChange={e=>{setPassword(e.target.value)}}
                    invalid={errors?true:false}
                    required
                  />
                  </InputGroup> */}

                  {/* <Label>Password</Label>
                    <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    name='password_confirmation'
                    autoComplete="new-password"
                    value={password_confirmation}
                    onChange={e=>{setPasswordConfirm(e.target.value)}}
                    invalid={errors?true:false}
                    required
                  />
                  </InputGroup> */}
                {/* </FormGroup> */}
                
            
                <div className="text-center">
                  <Button className="mt-4" color="primary" type="submit">
                     Reset password
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
          <Row className="mt-3">
          <Col className="text-right" xs="6">
            <Link to="/auth/login"
              className="text-light"
              
              // onClick={(e) => e.preventDefault()}
            >
              <small>Sign in</small>
            </Link>
          </Col>
        </Row>
        </Col>
      </>
    );
  };
  
  export default ResetPassword;
  