import axios from 'axios';
import { Url } from 'constants/urls';
import { getToken } from 'services/auth/Token';

export const getPopup = async () => {
    try {
        const response = await axios.get(`${Url}popup`,{
            headers:{
                "Accept":"application/json"
            }
        });
        return response.data.content;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const updatePopupContent= async (data)=>{
    try{
       const res = await axios.put(`${Url}popup`,data,{
                headers:{
                    // "Content-Type": "multipart/form-data",
                    "Content-Type":"application/json",
                    "Accept":'application/json',
                    "Authorization":`Bearer ${getToken()}`
            }});
        return res.data.message

    }catch(e){
        console.error('Error updating popup content:', e);
        // You can throw a custom error or return a custom error message
        throw new Error('Failed to update popup content. Please try again later.');
    }
}
