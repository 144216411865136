/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useIdleTimer } from "react-idle-timer";
import AuthUser from "services/auth/AuthUser";
import Swal from "sweetalert2";

export default function IdleTimerContainer() {
  const timeout =45 * 60 * 1000; //30 * 60 * 1000; //30min
  // const [remaining, setRemaining] = useState(timeout);
  // const [elapsed, setElapsed] = useState(0);
  // const [lastActive, setLastActive] = useState(+new Date());
  const {logout,getToken}=AuthUser()
  const handleOnIdle = () => {
    if(getToken())
      {
        logout()
        Swal.fire(
          { 
            title:`you have been disconnected due to inactivity`,
            confirmButtonText: 'Ok',
          }
        )
        
        
      }
  };

  useIdleTimer({
    timeout,
    onIdle: handleOnIdle
  });

  // const handleReset = () => reset();
  // const handlePause = () => pause();
  // const handleResume = () => resume();

  // useEffect(() => {
  //   setRemaining(getRemainingTime());
  //   setLastActive(getLastActiveTime());
  //   setElapsed(getElapsedTime());

  //   setInterval(() => {
  //     setRemaining(getRemainingTime());
  //     setLastActive(getLastActiveTime());
  //     setElapsed(getElapsedTime());
  //   }, 1000);
  // }, []);

  return (
    <div>
    </div>
  );
}
