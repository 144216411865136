import axios from 'axios';
import Header from 'components/Headers/Header'
import { Url } from 'constants/urls';
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'
import { getToken } from 'services/auth/Token';
import { updatePopupContent } from 'services/popup/PopupService';
import { getPopup } from 'services/popup/PopupService';
import Swal from 'sweetalert2';

function Popup() {
    const [ data , setData] = useState();
    const fetchPopupData = async () => {
        try {
            const popupData = await getPopup();
            setData(popupData);
        } catch (error) {
            console.error('Failed to fetch popup data:', error);
        }
    };
    const handleChange = (event)=>{
        setData(prev=>{
            return {
                ...prev,
                [event.target.name]:event.target.value,
                //i did this on purpose so that if they edit this popup by mistake it wont be displayed 
                //until they correct it or they can check it so it will be displayed
                is_active: event.target.checked,
            }})
    }
    const updatePopup=async (e)=>{
        e.preventDefault()
        try{

            const message = await updatePopupContent(data)
            // console.log(message)
            Swal.fire({
                icon:'success',
                // title:'mission has been updated',
                text:message,
                showConfirmButton:false,
                timer: 2000,
            })
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Failed to update popup content. Please try again later.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
                // setData({})
    }
    useEffect(()=>{
        fetchPopupData()
    },[])
    
  return (
    <div>
         <Header />
      {/* Page content */}
        <Container className="mt--7" fluid>
            <Card className='p-3'>
                <h2 className='text-danger p-2'>Popup Overview</h2>
                {data && <Card>
                    <CardHeader>
                        <h1 className='text-center m-4'>
                            {data.title}
                        </h1>
                        <p className='text-center'>
                            {data.content}
                        </p>
                    </CardHeader>
                </Card>}
                
            </Card>
            <hr/>
            {data && <Card className='p-3'>
                <form onSubmit={updatePopup}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="title">
                                    Title
                                </Label>
                                <Input
                                    id="title"
                                    name="title"
                                    placeholder="name  placeholder"
                                    type="text"
                                    value={data.title} 
                                    onChange={handleChange}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="title_fr">
                                    Title (fr)
                                </Label>
                                <Input
                                    id="title_fr"
                                    name="title_fr"
                                    placeholder="title in french"
                                    type="text"
                                    value={data.title_fr}
                                    onChange={handleChange}
                                    required
                                />
                            </FormGroup>
                        </Col>    
                        <Col>
                            <FormGroup>
                                <Label for="title_nl">
                                    Title (Nl)
                                </Label>
                                <Input
                                    id="title_nl"
                                    name="title_nl"
                                    placeholder="title in dutch"
                                    type="text"
                                    value={data.title_nl}
                                    onChange={handleChange}
                                    required
                                />
                            </FormGroup>
                        </Col>        
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="content">
                                    Content
                                </Label>
                                <Input
                                    id="content"
                                    name="content"
                                    placeholder="content in english"
                                    type="textarea"
                                    value={data.content}
                                    rows={6}
                                    onChange={handleChange}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="content_fr">
                                    Content(Fr)
                                </Label>
                                <Input
                                    id="content_fr"
                                    name="content_fr"
                                    placeholder="content in french"
                                    type="textarea"
                                    value={data.content_fr}
                                    rows={6}
                                    onChange={handleChange}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="content_nl">
                                    Content(Nl)
                                </Label>
                                <Input
                                    id="content_nl"
                                    name="content_nl"
                                    placeholder="content in dutch"
                                    type="textarea"
                                    value={data.content_nl}
                                    rows={6}
                                    onChange={handleChange}
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className='p-3'>
                        <FormGroup>
                            <Label className='mx-2'>Is Active</Label>
                            <span className='mx-2'>
                                (if this is checked it will show in the website)
                            </span>
                            <input
                                id="is_active"
                                type='checkbox'
                                name="is_active"
                                checked={data.is_active}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Row>
                    <Button className='btn-success'>
                        update
                    </Button>
                </form>
            </Card>}
        </Container>
    </div>
  )
}

export default Popup