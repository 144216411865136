import axios from 'axios'
import Header from 'components/Headers/Header'
import { Url } from 'constants/urls'
import React, { useEffect, useState } from 'react'
import FormData from 'form-data';
// import { Form } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'
import AuthUser from 'services/auth/AuthUser'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
// import { fetchRoomType } from 'services/rooms/RoomsService'

function AddRoom() {
    const navigate = useNavigate()
    const {getToken} = AuthUser()
    const [roomTypes, setRoomTypes]=useState([])
    async function fetchData() {
        try {
        await axios.get(`${Url}roomtype`,{
            headers:{
                'Authorization':`Bearer ${getToken()}`
            }
        }).then(res => {
            const types = res.data.room_types
            setRoomTypes(types)
        }).catch(e=>console.log(e))
        // return response.data.room_types
    }
    catch(e){
        console.error('Error fetching rooms types:', e);
        throw e;
        }
    }
    //form data 
    const [name,setName]=useState('')
    const [name_fr,setName_fr]=useState('')
    const [name_nl,setName_nl]=useState('')
    const [room_type_id,setRoomType]=useState('')
    const [description,setDescription]=useState('')
    const [description_fr,setDescription_fr]=useState('')
    const [description_nl,setDescription_nl]=useState('')
    const [size,setSize]=useState(0)
    const [capacity,setCapacity]=useState(1)
    const [price,setPrice]=useState(0)
    const [main_image, setMainImage]=useState('')
    const [image_1, setImage_1]=useState('')
    const [image_2, setImage_2]=useState('')
    const [image_3, setImage_3]=useState('')

    
    async function createRoom(e){
        e.preventDefault();
        const formData = new FormData();
        formData.append('name',name)
        formData.append('name_fr',name_fr)
        formData.append('name_nl',name_nl)
        formData.append('room_type_id',room_type_id)
        formData.append('description',description)
        formData.append('description_fr',description_fr)
        formData.append('description_nl',description_nl)
        formData.append('size',size)
        formData.append('price',price)
        formData.append('capacity',capacity)
        formData.append('main_image',main_image)
        formData.append('image_1',image_1)
        formData.append('image_2',image_2)
        formData.append('image_3',image_3)
        // console.log(Object.fromEntries(formData))
        await axios.post(`${Url}rooms`,formData,{
            headers:{
                // 'Content-type':"application/json",
                'Accept':'application/json',
                "Content-Type": "multipart/form-data",            
                "Authorization":`Bearer ${getToken()}`
            }
        }).then((response)=>{
            
                // console.log(response.data.message)
                Swal.fire({
                    icon:'success',
                    // title:'mission has been updated',
                    text:response.data.message,
                    showConfirmButton:false,
                    timer: 2000,
                })
                navigate(-1);
            
        }).catch(e=>console.log(e))
    }
    // console.log(price)
    useEffect(()=>{
        fetchData()
        if(!getToken()) {
          navigate('/')
        }
    },[navigate])
  return (
    <div>
        <Header/>
        <Container className="mt--7" fluid>
        <Card className="shadow">
            <CardHeader className="border-0 px-6">
                <h3>Add room</h3>
            </CardHeader>
            <CardBody>
                <form className='p-5' onSubmit={createRoom}>
                    <Row>
                        <Col md={4}>
                        <FormGroup>
                            <Label for="exampleEmail">
                            Room Name
                            </Label>
                            <Input
                            id="exampleEmail"
                            name="name"
                            placeholder="name  placeholder"
                            type="text"
                            value={name} onChange={(e)=>{setName(e.target.value)}}
                            required
                            />
                        </FormGroup>
                        </Col>
                        <Col md={4}>
                        <FormGroup>
                            <Label for="examplePassword">
                            Room Name (francais)
                            </Label>
                            <Input
                            id="examplePassword"
                            name="name_fr"
                            placeholder="name placeholder"
                            value={name_fr} onChange={(e)=>{setName_fr(e.target.value)}}
                            type="text"
                            required
                            />
                        </FormGroup>
                        </Col>
                        <Col md={4}>
                        <FormGroup>
                            <Label for="examplePassword">
                            Room Name (Dutsh)
                            </Label>
                            <Input
                            id="examplePassword"
                            name="name_nl"
                            placeholder="name placeholder"
                            value={name_nl} onChange={(e)=>{setName_nl(e.target.value)}}
                            type="text"
                            required
                            />
                        </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label>
                            Room Type
                        </Label>
                        <Input
                            className="mb-3"
                            type="select"
                            name='room_type_id'
                            defaultValue={'Default'}
                            onChange={(event)=>{
                                
                                setRoomType(event.target.value)}}
                            required
                        >
                            <option disabled value='Default'>
                            Select Room Type
                            </option>
                            {roomTypes.map(type=>
                                <option value={type.id} key={type.id}>
                                    {type.name}
                                </option>
                            )}
                        </Input>
                    </FormGroup>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="capacity">
                                    Capacity (persons)
                                </Label>
                                <Input
                                    id="capacity"
                                    name="capacity"
                                    placeholder=""
                                    // defaultValue={0}
                                    value={capacity} 
                                    onChange={(e)=>{setCapacity(e.target.value)}}
                                    type='number'
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="exampleAddress2">
                                    Size (m²)
                                </Label>
                                <Input
                                    id="exampleAddress2"
                                    name="size"
                                    placeholder=""
                                    // defaultValue={0}
                                    value={size} 
                                    onChange={(e)=>{setSize(e.target.value)}}
                                    type='number'
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                        <FormGroup>
                            <Label for="exampleAddress2">
                                Price (€)
                            </Label>
                            <Input
                            id="exampleAddress2"
                            name="price"
                            placeholder=""
                            step="0.01"
                            // defaultValue={0}
                            value={price}
                            onChange={(e)=>{setPrice(e.target.value)}}
                            type='number'
                            required
                            />
                        </FormGroup>
                    </Col>
                   </Row>
                    <FormGroup>
                        <Label for="exampleAddress">
                            Description
                        </Label>
                        <Input
                        id="exampleAddress"
                        name="description"
                        placeholder="write description"
                        value={description} onChange={(e)=>{setDescription(e.target.value)}}
                        required
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="exampleAddress2">
                            Description (francais)
                        </Label>
                        <Input
                        id="exampleAddress2"
                        name="description_fr"
                        placeholder="write description in french"
                        value={description_fr} onChange={(e)=>{setDescription_fr(e.target.value)}}
                        
                        required
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="exampleAddress2">
                            Description (Dutch)
                        </Label>
                        <Input
                        id="exampleAddress2"
                        name="description_nl"
                        placeholder="write description in dutch"
                        value={description_nl} onChange={(e)=>{setDescription_nl(e.target.value)}}
                        
                        required
                        />
                    </FormGroup>
                   
                    <Row className='mb-5'>
                        <Col>
                            <Label>
                                Main image
                            </Label>
                            <Input 
                                type='file'
                                name="main_image"
                                accept="image/*"
                                onChange={(event)=>{setMainImage(event.target.files[0])}}
                                required
                            />
                        </Col>
                        <Col>
                            <Label>
                                Image 1
                            </Label>
                            <Input 
                                type='file'
                                name="image_1"
                                accept="image/*"
                                onChange={(event)=>{setImage_1(event.target.files[0])}}
                                required
                            />
                        </Col>
                        <Col>
                            <Label>
                                Image 2
                            </Label>
                            <Input 
                                type='file'
                                name="image_2"
                                accept="image/*"
                                onChange={(event)=>{setImage_2(event.target.files[0])}}
                                required
                            />
                        </Col>
                        <Col>
                            <Label>
                                Image 3
                            </Label>
                            <Input 
                                type='file'
                                name="image_3"
                                accept="image/*"
                                onChange={(event)=>{setImage_3(event.target.files[0])}}
                                required
                            />
                        </Col>
                    </Row>
                    
                    <Button>
                        Add Room
                    </Button>
                    </form>
            </CardBody>
        </Card>
        </Container>
    </div>
  )
}

export default AddRoom