import axios from 'axios'
import Header from 'components/Headers/Header'
import { BaseUrl } from 'constants/urls'
import { Url } from 'constants/urls'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap'
import AuthUser from 'services/auth/AuthUser'
import Swal from 'sweetalert2'

function UpdateRoom() {
    const navigate = useNavigate()
    const {slug}=useParams();
    const {getToken} =AuthUser()
    const [roomTypes, setRoomTypes]=useState([])
    const [main_image, setMainImage]=useState('')
    const [image_1, setImage_1]=useState('')
    const [image_2, setImage_2]=useState('')
    const [image_3, setImage_3]=useState('')
    async function fetchData() {
        try {
        await axios.get(`${Url}roomtype`,{
            headers:{
                'Authorization':`Bearer ${getToken()}`
            }
        }).then(res => {
            const types = res.data.room_types
            setRoomTypes(types)
        }).catch(e=>console.log(e))
        // return response.data.room_types
    }
    catch(e){
        console.error('Error fetching rooms types:', e);
        throw e;
        }
    }
    const [data, setData]= useState({

    })
    
    async function getRoomData(){
        await axios.get(`${Url}rooms/room/${slug}`)
        .then((res)=>setData(res.data.room))
        .catch(e=>console.log(e))
    }
    //handle change function
    function handleChanges(event){
        setData(prev=>{
            return {
                ...prev,
                [event.target.name]:event.target.value,
            }
        })
    }
    //update functions
        //-1 if there is images we need to call update images
        async function updateImages(e){
            e.preventDefault();
            const formData = new FormData();
            formData.append('main_image',main_image)
            formData.append('image_1',image_1)
            formData.append('image_2',image_2)
            formData.append('image_3',image_3)
            if(data){
                await axios.post(`${Url}rooms/update-images/${data.id}?_method=PUT`,formData,{
                    headers:{
                        // 'Content-type':"application/json",
                        'Accept':'application/json',
                        "Content-Type": "multipart/form-data",            
                        "Authorization":`Bearer ${getToken()}`
                    }
                }).then((response)=>{
                    
                        console.log(response.data)
                        // navigate(-1);
                    
                }).catch(e=>console.log(e))

            }
        }
         //-2 update rooms info
            function updateData(e){
                e.preventDefault()
                axios.put(`${Url}rooms/${data.id}`,data,{
                    headers:{
                        // "Content-Type": "multipart/form-data",
                        "Content-Type":"application/json",
                        "Accept":'application/json',
                        "Authorization":`Bearer ${getToken()}`
                }})
                .then((response) => {
                    console.log(response.data.message)
                    Swal.fire({
                        icon:'success',
                        // title:'mission has been updated',
                        text:response.data.message,
                        showConfirmButton:false,
                        timer: 2000,
                    })
                    getRoomData()
                        // setData({})
                }).catch(e=>console.log(e))
            }



        
        function updateRoom(e){
            updateImages(e)
            updateData(e)
            
        }
        // console.log(data)
    useEffect(()=>{
        fetchData()
        getRoomData()
        if(!getToken()) {
          navigate('/')
        }
    },[])
  return (
    <div>
        <Header/>
        <Container className="mt--7" fluid>
        <Card className="shadow">
            <CardHeader className="border-0 px-6">
                <h3>Update room</h3>
            </CardHeader>
            <CardBody>
                <form className='p-5' 
                    onSubmit={updateRoom}
                >
                    <Row>
                        <Col md={4}>
                        <FormGroup>
                            <Label for="exampleEmail">
                            Room Name
                            </Label>
                            <Input
                            id="exampleEmail"
                            name="name"
                            placeholder="name  placeholder"
                            type="text"
                            value={data.name} 
                            readOnly
                            onChange={handleChanges}
                            required
                            />
                        </FormGroup>
                        </Col>
                        <Col md={4}>
                        <FormGroup>
                            <Label for="examplePassword">
                            Room Name (francais)
                            </Label>
                            <Input
                            id="examplePassword"
                            name="name_fr"
                            placeholder="name placeholder"
                            value={data.name_fr} 
                            onChange={handleChanges}
                            type="text"
                            readOnly
                            required
                            />
                        </FormGroup>
                        </Col>
                        <Col md={4}>
                        <FormGroup>
                            <Label for="examplePassword">
                            Room Name (Dutsh)
                            </Label>
                            <Input
                            id="examplePassword"
                            name="name_nl"
                            placeholder="name placeholder"
                            value={data.name_nl}
                            readOnly
                             onChange={handleChanges}
                                                    type="text"
                            required
                            />
                        </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label>
                            Room Type
                        </Label>
                        <Input
                            className="mb-3"
                            type="select"
                            name='room_type_id'
                            defaultValue={'Default'}
                            value={data.room_type_id}
                            // onChange={(event)=>{
                                
                                // setRoomType(event.target.value)}}
                            required
                        >
                            <option disabled value='Default'>
                            Select Room Type
                            </option>
                            {roomTypes.map(type=>
                                <option value={type.id} key={type.id}>
                                    {type.name}
                                </option>
                            )}
                        </Input>
                    </FormGroup>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="capacity">
                                    Capacity (persons)
                                </Label>
                                <Input
                                    id="capacity"
                                    name="capacity"
                                    placeholder=""
                                    defaultValue={1}
                                    value={data.capacity} 
                                    onChange={handleChanges}
                                    type='number'
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="exampleAddress2">
                                    Size (m²)
                                </Label>
                                <Input
                                    id="exampleAddress2"
                                    name="size"
                                    placeholder=""
                                    defaultValue={0}
                                    value={data.size} 
                                    onChange={handleChanges}
                                    type='number'
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                        <FormGroup>
                            <Label for="exampleAddress2">
                                Price (€)
                            </Label>
                            <Input
                            id="exampleAddress2"
                            name="price"
                            placeholder=""
                            step="0.01"
                            defaultValue={0}
                            value={data.price}
                            onChange={handleChanges}
                            type='number'
                            required
                            />
                        </FormGroup>
                    </Col>
                   </Row>
                    <FormGroup>
                        <Label for="exampleAddress">
                            Description
                        </Label>
                        <Input
                        rows={6}
                        id="exampleAddress"
                        type='textarea'
                        name="description"
                        placeholder="write description"
                        value={data.description} 
                        onChange={handleChanges}
                        required
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="exampleAddress2">
                            Description (francais)
                        </Label>
                        <Input
                        rows={6}
                        type='textarea'
                        id="exampleAddress2"
                        name="description_fr"
                        placeholder="write description in french"
                        value={data.description_fr}
                         onChange={handleChanges}
                        required
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="exampleAddress2">
                            Description (Dutch)
                        </Label>
                        <Input
                        type='textarea'
                        id="exampleAddress2"
                        rows={6}
                        name="description_nl"
                        placeholder="write description in dutch"
                        value={data.description_nl} 
                        onChange={handleChanges}
                        required
                        />
                    </FormGroup>
                    <Row className='mb-5'>
                        <Col>
                            <div>
                                <img src={`${BaseUrl}/${data.main_image}`} alt='main_image' width={200} height={200}/>

                            </div>
                            <Label>
                                Main image
                            </Label>
                            <Input 
                                type='file'
                                name="main_image"
                                accept="image/*"
                                onChange={(event)=>{setMainImage(event.target.files[0])}}
                                
                            />
                        </Col>
                        <Col>
                            <div>
                                <img src={`${BaseUrl}/${data.image_1}`} alt='image_1' width={200} height={200}/>

                            </div>
                            <Label>
                                Image 1
                            </Label>
                            <Input 
                                type='file'
                                name="image_1"
                                accept="image/*"
                                onChange={(event)=>{setImage_1(event.target.files[0])}}
                                
                            />
                        </Col>
                        <Col>
                            <div>
                                <img src={`${BaseUrl}/${data.image_2}`} alt='image_2' width={200} height={200}/>

                            </div>
                            <Label>
                                Image 2
                            </Label>
                            <Input 
                                type='file'
                                name="image_2"
                                accept="image/*"
                                onChange={(event)=>{setImage_2(event.target.files[0])}}
                                
                            />
                        </Col>
                        <Col>
                            <div>
                                <img src={`${BaseUrl}/${data.image_3}`} alt='image_3' width={200} height={200}/>

                            </div>
                            
                            <Label>
                                Image 3
                            </Label>
                            <Input 
                                type='file'
                                name="image_3"
                                accept="image/*"
                                onChange={(event)=>{setImage_3(event.target.files[0])}}
                                
                            />
                        </Col>
                    </Row>
                    {/* <Row className='mb-5'>
                        <Col>
                            <Label>
                                Main image
                            </Label>
                            <Input 
                                type='file'
                                name="main_image"
                                accept="image/*"
                                onChange={(event)=>{setMainImage(event.target.files[0])}}
                                
                            />
                        </Col>
                        <Col>
                            <Label>
                                Image 1
                            </Label>
                            <Input 
                                type='file'
                                name="image_1"
                                accept="image/*"
                                onChange={(event)=>{setImage_1(event.target.files[0])}}
                                
                            />
                        </Col>
                        <Col>
                            <Label>
                                Image 2
                            </Label>
                            <Input 
                                type='file'
                                name="image_2"
                                accept="image/*"
                                onChange={(event)=>{setImage_2(event.target.files[0])}}
                                
                            />
                        </Col>
                        <Col>
                            <Label>
                                Image 3
                            </Label>
                            <Input 
                                type='file'
                                name="image_3"
                                accept="image/*"
                                onChange={(event)=>{setImage_3(event.target.files[0])}}
                                
                            />
                        </Col>
                    </Row> */}
                    
                    <Button>
                        Update
                    </Button>
                    </form>
            </CardBody>
        </Card>
        </Container>
    </div>
  )
}

export default UpdateRoom